// Material Dashboard 2 React example components
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MDBox from "components/MDBox";
import Footer from "../../components/Footer";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";
import { Link } from "react-router-dom";
import scheduleBG from "assets/images/corporate2.jpg";
import APIError from "components/ApiError";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import paymentAPIService from "services/paymentAPI-service";
import BulkMessage from "./bulkSMS";
import SendCustomMessage from "./customSMS";
import UnpaidReminders from "./unpaidReminders";

function SMS() {
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = ["Payer", "Message", "Status"];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [addCustomMessageDialogOpen, setAddCustomMessageDialogOpen] =
    useState(false);
  const [addUnpaidMessageDialogOpen, setAddUnpaidMessageDialogOpen] =
    useState(false);
  const [addBulkMessageDialogOpen, setAddBulkMessageDialogOpen] =
    useState(false);

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  // Fetch sms log
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchSmsLogs(); // Call the API after the debounce delay
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(debounceTimer); // Clear the timeout if the dependencies change
    };
  }, [page, rowsPerPage]);

  const fetchSmsLogs = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const smsLogsList = await paymentAPIService.getSMSLogs(
        rowsPerPage,
        page + 1
      );
      setRows(smsLogsList.data.results);
      setTotal(smsLogsList.data.count);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleAddUnpaidMessage = () => {
    setAddUnpaidMessageDialogOpen(true);
  };

  const handleAddNewCustomMessage = () => {
    setAddCustomMessageDialogOpen(true);
  };

  const handleAddBulkMessage = () => {
    setAddBulkMessageDialogOpen(true);
  };

  const handleCustomMessageDialogClose = (response) => {
    if (response === "Close") {
      setAddCustomMessageDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Message has been successfully sent.");
      openSnackBar();
    }
    setAddCustomMessageDialogOpen(false);
  };

  const handleUnpaidMessageDialogClose = (response) => {
    if (response === "Close") {
      setAddUnpaidMessageDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Message has been successfully sent.");
      openSnackBar();
    }
    setAddUnpaidMessageDialogOpen(false);
  };

  const handleBulkMessageDialogClose = (response) => {
    if (response === "Close") {
      setAddBulkMessageDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Message has been successfully sent.");
      openSnackBar();
    }
    setAddBulkMessageDialogOpen(false);
  };

  const renderAddCustomMessageDialog = (
    <SendCustomMessage
      open={addCustomMessageDialogOpen}
      onClose={handleCustomMessageDialogClose}
    />
  );

  const renderUnpaidMessageDialog = (
    <UnpaidReminders
      open={addUnpaidMessageDialogOpen}
      onClose={handleUnpaidMessageDialogClose}
    />
  );

  const renderBulkMessageDialog = (
    <BulkMessage
      open={addBulkMessageDialogOpen}
      onClose={handleBulkMessageDialogClose}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={10} pb={10} overflow="auto">
        <Card>
          {/* Heading */}
          <MDBox
            mx={2}
            mt={-6}
            py={4}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="xxl"
            coloredShadow="info"
            height="100px"
            sx={{
              backgroundImage: ({
                functions: { rgba, linearGradient },
                palette: { gradients },
              }) =>
                `${linearGradient(
                  rgba(gradients.info.main, 0.7),
                  rgba(gradients.info.state, 0.1)
                )}, url(${scheduleBG})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          >
            <MDTypography variant="h4" color="light">
              SMS
            </MDTypography>
          </MDBox>

          {/*  Buttons */}
          <MDBox
            mb={1}
            mt={2}
            pr={2}
            display="flex"
            justifyContent="end"
            gap={2}
            flexWrap="wrap"
          >
            <MDButton
              variant="contained"
              color="dark"
              type="button"
              onClick={handleAddUnpaidMessage}
            >
              + Send Unpaid Reminder
            </MDButton>

            <MDButton
              variant="contained"
              color="dark"
              type="button"
              onClick={handleAddBulkMessage}
            >
              + Send Bulk Message
            </MDButton>

            <MDButton
              variant="contained"
              color="dark"
              type="button"
              onClick={handleAddNewCustomMessage}
            >
              + Send New Message
            </MDButton>
          </MDBox>

          {/* Processing Bar */}
          {processing && (
            <MDBox pt={2} px={2}>
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            </MDBox>
          )}

          {/* No SMS */}
          {!processing && !apiError && total === 0 && (
            <MDBox pt={3} px={4} pb={5}>
              <MDTypography variant="h7" color="error">
                No sms has been sent
              </MDTypography>
            </MDBox>
          )}

          {/* Table */}
          {!processing && total > 0 && (
            <MDBox pt={3} px={1} pb={5}>
              <TableContainer component={Paper}>
                <Table>
                  {/* Header Columns */}
                  <TableHead>
                    <TableRow>
                      {columns.map((column, i) => (
                        <TableCell key={i}>{column}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* Body Contents */}
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Link to={`/payers/${row.payer}`}>
                            <MDTypography
                              variant="h7"
                              fontWeight="bold"
                              color="info"
                            >
                              {row?.payer_name}
                            </MDTypography>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <MDTypography variant="h7" fontWeight="regular">
                            {row.message}
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography
                            variant="h7"
                            fontWeight="bold"
                            color={
                              row.status === "success" ? "success" : "error"
                            }
                            textTransform="capitalize"
                          >
                            {row.status}
                          </MDTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </MDBox>
          )}
        </Card>
      </MDBox>

      {!processing && apiError && <APIError />}
      {addCustomMessageDialogOpen && renderAddCustomMessageDialog}
      {addUnpaidMessageDialogOpen && renderUnpaidMessageDialog}
      {addBulkMessageDialogOpen && renderBulkMessageDialog}

      {renderSnackBar}
      <Footer />
    </DashboardLayout>
  );
}

export default SMS;
