import axiosInstance from "./htttp.service";

class PaymentApiService {
  axiosInstances = axiosInstance;

  // A list of All Student List
  addPayment = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(`attendance/paid-amount/`, payload, {
      headers,
    });
  };

  getMostRecentPayment = async (id) => {
    return await this.axiosInstances.get(
      `/attendance/most-recent-paid-amount/${id}`
    );
  };

  deletePayment = async (id) => {
    return await this.axiosInstances.delete(`/attendance/paid-amount/${id}`);
  };

  downloadCSV = async (startDate, endDate) => {
    return await this.axiosInstances.get(
      `/attendance/paid-amount/download_csv/?&payment_date_after=${startDate}&payment_date_before=${endDate}`
    );
  };

  downloadStudentCSV = async (id, startDate, endDate) => {
    return await this.axiosInstances.get(
      `/attendance/paid-amount/download_csv/?student=${id}&payment_date_after=${startDate}&payment_date_before=${endDate}`
    );
  };

  // Add Cost Per Class
  addCostPerClass = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/cost-per-class/`,
      payload,
      {
        headers,
      }
    );
  };

  // Get all cost per class
  getAllCostPerClass = async () => {
    return await this.axiosInstances.get(`/attendance/cost-per-class/`);
  };

  // Get  cost per class
  deleteCostPerClass = async (id) => {
    return await this.axiosInstances.delete(`/attendance/cost-per-class/${id}`);
  };

  // addStudentCostPerClass
  addStudentCostPerClass = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/cost-for-student/`,
      payload,
      {
        headers,
      }
    );
  };

  // addStudentCostPerClass
  updateStudentCostPerClass = async (payload, id) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.put(
      `attendance/cost-for-student/${id}`,
      payload,
      {
        headers,
      }
    );
  };

  // Get all payment methods
  getAllPaymentMethods = async () => {
    return await this.axiosInstances.get(`/attendance/payment-method/`);
  };

  // A list of All SMS Logs
  getSMSLogs = async (pageSize, pageIndex) => {
    return await this.axiosInstances.get(
      `/payment/sms-logs/?page_size=${pageSize}&page=${pageIndex}&ordering=-timestamp`
    );
  };
}

export default new PaymentApiService();
