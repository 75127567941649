import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Icon from "@mui/material/Icon";
import Slide from "@mui/material/Slide";
import phoneNumberMask from "components/Formatter/PhoneMask";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef, useEffect, useState } from "react";
import payerAPIService from "services/payerAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SendCustomMessage(props) {
  const { open, onClose } = props;

  // Search Student By Name
  const [name, setName] = useState("");
  const [apiError, setApiError] = useState(false);
  const [searchProcessing, setSearchProcessing] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");

  const [selectedPayers, setSelectedPayers] = useState([]);
  const columns = ["Name", "Email", "Phone", "Action"];
  // For this form
  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    message: "",
    payerIds: [],
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    message: false,
    payerIds: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
    setSelectedPayers([]);
    setProcessing(false);
  };

  // Searching for Students
  const handleNameSearch = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchPayersList(); // Call the API after the debounce delay
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(debounceTimer); // Clear the timeout if the dependencies change
    };
  }, [name]);

  const fetchPayersList = async () => {
    setSearchProcessing(true);
    setApiError(false);
    try {
      const response = await payerAPIService.getPayersList(
        rowsPerPage,
        page + 1,
        "",
        name,
        orderBy
      );
      setRows(response.data.results);
      setTotal(response.data.count);

      setSearchProcessing(false);
    } catch (error) {
      setApiError(true);
      setSearchProcessing(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectPayer = (payer, condition) => {
    if (condition) {
      setSelectedPayers([...selectedPayers, payer]);
    } else {
      let existingPayers = selectedPayers.filter(
        (row) => row.uuid != payer.uuid
      );
      setSelectedPayers([...existingPayers]);
    }
  };

  const isPayerSelected = (row) => {
    let payerSelected = false;

    selectedPayers.forEach((payer) => {
      if (payer.uuid === row.uuid) {
        payerSelected = true;
      }
    });

    return payerSelected;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!selectedPayers || selectedPayers.length == 0) {
      setErrors({ ...errors, payer: true });
      return;
    } else {
      setErrors({ ...errors, payer: false });
    }

    if (!inputs.message || inputs.message.length == 0) {
      setErrors({ ...errors, message: true });
      return;
    } else {
      setErrors({ ...errors, message: false });
    }

    // Now that everything is verified
    let payerUUIDS = [];
    selectedPayers.forEach((payer) => {
      payerUUIDS.push(payer.uuid);
    });
    setProcessing(true);
    try {
      let payload = {
        message: inputs.message,
        payer_uuids: payerUUIDS,
      };
      const response = await payerAPIService.sendCustomMessage(payload);
      setProcessing(false);
      setInputs({ ...initialState });
      setSelectedPayers([]);
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to send the message.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      slots={{ transition: Transition }}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Send new message
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="regular">
                <span style={{ color: "red" }}>*</span> Message
              </MDTypography>
              <MDInput
                type="text"
                value={inputs.message}
                name="message"
                onChange={changeHandler}
                error={errors.message}
                fullWidth
                multiline
                minRows="3"
              />
            </MDBox>

            {/* //  Search Payer */}
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="regular">
                <span style={{ color: "red" }}>*</span> Please use the search
                box below to find the payer
              </MDTypography>
              <MDInput
                placeholder="Search Payer by name..."
                value={name}
                size="medium"
                onChange={handleNameSearch}
                sx={{
                  width: "100%",
                  background: "white",
                  borderRadius: "6px",
                }}
                type="search"
                InputProps={{
                  startAdornment: (
                    <Icon sx={{ color: "gray" }} fontSize="small">
                      search
                    </Icon>
                  ),
                }}
              />
            </MDBox>

            {/* Progress Bar */}
            {searchProcessing && (
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            )}

            {/* No Data */}
            {!searchProcessing && !apiError && total == 0 && (
              <MDBox>
                <MDTypography variant="h7" color="error">
                  No payer found
                </MDTypography>
              </MDBox>
            )}

            {/* Students */}
            {!searchProcessing && !apiError && total > 0 && (
              <MDBox pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={row.id + " " + index}>
                          <TableCell>
                            <MDTypography
                              variant="h7"
                              fontWeight="bold"
                              color="text"
                            >
                              {row.first_name} {row.last_name}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            <MDTypography
                              variant="h7"
                              fontWeight="regular"
                              color="text"
                            >
                              {row.email}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            {row?.phone && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                {phoneNumberMask(row.phone)}
                              </MDTypography>
                            )}
                          </TableCell>

                          <TableCell>
                            {/* If the student is not selected */}
                            {!isPayerSelected(row) && (
                              <MDButton
                                type="button"
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={() => selectPayer(row, true)}
                              >
                                Select
                              </MDButton>
                            )}
                            {/* If the student is selected */}
                            {isPayerSelected(row) && (
                              <MDButton
                                type="button"
                                variant="contained"
                                color="error"
                                size="small"
                                onClick={() => selectPayer(row, false)}
                              >
                                Remove
                              </MDButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              </MDBox>
            )}
          </MDBox>
        </MDBox>

        {/* Selected Count */}
        <MDTypography variant="h7" color="text" fontWeight="bold" mb={2}>
          {selectedPayers.length} payers selected
        </MDTypography>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default SendCustomMessage;
