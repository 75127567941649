// @mui material components

// Material Dashboard 2 React components
import { Link, useParams } from "react-router-dom";
// Material Dashboard 2 React example components
import APIError from "components/ApiError";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../layouts/LayoutContainers/DashboardLayout";
// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import payerAPIService from "services/payerAPI-service";
import AddPayerDialog from "../addPayer";
// Material Dashboard 2 React components
import parentBG from "assets/images/parent_bg1.png";
import avatar from "assets/images/payer_avatar.png";
import studentAvatar from "assets/images/person.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

import { Icon, LinearProgress, Tooltip } from "@mui/material";
import DisplayBox from "appStack/students/studentReports/ReportDisplayBox";
import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import phoneNumberMask from "components/Formatter/PhoneMask";
import AssociateStudentDialog from "../associateStudents";

function PayerStudents() {
  let { id } = useParams();

  const [payerName, setPayerName] = useState("");
  const [payerDetails, setPayerDetails] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  // For delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState("");

  const [associateStudentDialogOpen, setAssociateStudentDialogOpen] =
    useState(false);
  const [editPayerDialogOpen, setEditPayerDialogOpen] = useState(false);

  useEffect(() => {
    fetchPayerInformation();
  }, []);

  const fetchPayerInformation = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const response = await payerAPIService.getPayerInformation(id);
      setPayerName(response.data.first_name + " " + response.data.last_name);
      setPayerDetails(response.data);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleEditPayer = () => {
    setEditPayerDialogOpen(true);
  };

  const handleEditPayerDialogClose = (response) => {
    if (response === "Close") {
      setEditPayerDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Payer has been updated.");
      openSnackBar();
      fetchPayerInformation();
      setEditPayerDialogOpen(false);
    }
  };

  const handleAssociateStudent = () => {
    setAssociateStudentDialogOpen(true);
  };

  const handleAssociateStudentDialogClose = (response) => {
    if (response === "Close") {
      setAssociateStudentDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("New Student has been associated.");
      openSnackBar();
      fetchPayerInformation();
      setAssociateStudentDialogOpen(false);
    }
  };

  const removeStudent = (student) => {
    setDeleteItem(student);
    setDialogMessage(
      "Are you sure you want to remove this student from the payer?"
    );
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = async (response) => {
    if (response === "No") {
      setDeleteItem(null);
      setDeleteDialogOpen(false);
    } else {
      setDeleteProcessing(true);

      try {
        let payload = {
          payer: payerDetails.uuid,
          student_UUID: deleteItem.student_UUID,
        };

        const deleteResponse = await payerAPIService.detachStudent(payload);

        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage("Student has been removed from the payer.");
        openSnackBar();

        fetchPayerInformation();
        setDeleteProcessing(false);
        setDeleteItem(null);
        setDeleteDialogOpen(false);
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to remove the Student from the Payer.");
        openSnackBar();
        setDeleteProcessing(false);
        setDeleteDialogOpen(false);
      }
    }
  };

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const renderEditPayerDialog = (
    <AddPayerDialog
      open={editPayerDialogOpen}
      onClose={handleEditPayerDialogClose}
      existingDetails={payerDetails}
    />
  );

  const renderAssociateStudentDialog = (
    <AssociateStudentDialog
      open={associateStudentDialogOpen}
      onClose={handleAssociateStudentDialogClose}
      payerDetails={payerDetails}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const renderStudentDetach = (
    <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleDeleteClose}
      message={dialogMessage}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Displaying Payer Information */}

      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="14.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.2),
                rgba(gradients.info.state, 0.2)
              )}, url(${parentBG})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar
                src={avatar}
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h4" fontWeight="medium">
                  {payerName}
                  <MDButton
                    iconOnly
                    color="secondary"
                    variant="text"
                    size="medium"
                    onClick={handleEditPayer}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </MDTypography>
                {/* Email */}
                {payerDetails?.email && (
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    {payerDetails?.email}
                  </MDTypography>
                )}
                {/* Phone */}
                {payerDetails?.phone && (
                  <MDTypography variant="h8" color="text" fontWeight="regular">
                    <br />
                    {phoneNumberMask(payerDetails?.phone)}
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
          </Grid>

          {/* Progress Bar */}
          {processing && (
            <MDBox pt={2} px={2}>
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            </MDBox>
          )}

          {/* Display Associated Students */}

          {!processing && !apiError && (
            <MDBox mt={5} width="100%">
              {/* Heading */}
              <MDBox
                justifyContent="space-between"
                display="flex"
                flexWrap="wrap"
                gap={2}
                pt={3}
                px={2}
              >
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Associated Students
                </MDTypography>

                <MDButton
                  variant="contained"
                  color="dark"
                  size="small"
                  type="button"
                  onClick={handleAssociateStudent}
                >
                  Associate Student
                </MDButton>
              </MDBox>

              {/* No Associated Students */}
              {payerDetails?.associated_students?.length == 0 && (
                <MDBox pt={3} px={2} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No student has been associated with the payer
                  </MDTypography>
                </MDBox>
              )}

              {/* Associated Students */}
              {payerDetails?.associated_students?.length > 0 && (
                <MDBox pt={3} px={2} pb={5}>
                  {/* Grid Display */}
                  <Grid container spacing={2}>
                    {payerDetails?.associated_students.map((payer, index) => (
                      <Grid item xs={12} lg={6} key={"Payer" + index}>
                        {/* Outer Box */}
                        <MDBox
                          position="relative"
                          variant="contained"
                          sx={{ height: "100%" }}
                          shadow="sm"
                          coloredShadow="dark"
                          borderRadius="xl"
                          bgColor="grey-200"
                          px={2}
                          py={2}
                        >
                          {/* Display Student Information here */}
                          {/* Student Avatar + Name */}
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <MDAvatar
                              src={studentAvatar}
                              alt="profile-image"
                              size="xl"
                              shadow="sm"
                            />
                            <Tooltip title="View Student Details">
                              <Link to={`/students/${payer.student.id}`}>
                                <MDTypography
                                  variant="h6"
                                  color="info"
                                  sx={{ cursor: "pointer" }}
                                >
                                  {payer.student.first_name}{" "}
                                  {payer.student.last_name}
                                </MDTypography>
                              </Link>
                            </Tooltip>
                          </MDBox>

                          {/* Student Unpaid Amount */}
                          <MDBox p={0} m={0}>
                            <DisplayBox
                              color={
                                payer.student.unpaid_amount <= 0
                                  ? "success"
                                  : "error"
                              }
                              icon="price_change"
                              name={`Current Balance`}
                              description=""
                              value={`$ ${payer.student.unpaid_amount}`}
                            />
                            {/* Negative Indicator */}
                            {payer.student.unpaid_amount < 0 && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="secondary"
                              >
                                -ve indicates a credit amount
                              </MDTypography>
                            )}

                            {payer.student.unpaid_amount === 0 && (
                              <MDTypography variant="h7" color="success">
                                No Payment Due at this time
                              </MDTypography>
                            )}
                          </MDBox>

                          {/* Action */}
                          <MDBox position="absolute" right={2} top={1}>
                            <Tooltip title="Remove student">
                              <MDButton
                                iconOnly
                                color="error"
                                circular
                                size="small"
                                onClick={() => removeStudent(payer.student)}
                              >
                                <Icon fontSize="small">delete_forever</Icon>
                              </MDButton>
                            </Tooltip>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              )}
            </MDBox>
          )}

          {/* API Error */}
          {!processing && apiError && <APIError />}
        </Card>
      </MDBox>

      {renderSnackBar}
      {renderEditPayerDialog}
      {associateStudentDialogOpen && renderAssociateStudentDialog}
      {renderStudentDetach}
      {/* API Error */}
      {!processing && apiError && <APIError />}
      <Footer />
    </DashboardLayout>
  );
}

export default PayerStudents;
